'use client';

import {startTransition, useCallback, useContext, useMemo, useState} from 'react';
import {useDebounce} from 'react-use';
import type {SubscriptionDialogProps} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {SubscriptionDialog} from '~/app/(unauthenticated)/job-board/_/components/subscription-dialog';
import {useFilterState} from '~/app/(unauthenticated)/job-board/_/hooks/use-filter-state';
import {useJobBoardFilter} from '~/app/(unauthenticated)/job-board/_/hooks/use-job-board-filter';
import {KeywordsDialog} from '~/shared/components/dialogs/keywords';
import {PATHS} from '~/shared/constants/paths';
import {
	useJobBoardCities,
	useJobBoardCountries,
	useJobBoardIndustries,
	useJobBoardJobTypes,
	useJobBoardRegions,
} from '~/shared/data/job-board';
import {useSubscription} from '~/shared/data/subscription';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {JobBoardFilterContext} from '~/shared/providers/job-board-filter-provider';
import {isFilterValueDefined} from '~/shared/utils/job-board';
import {
	Badge,
	Button,
	Combobox,
	CopyButton,
	DropdownMenu,
	FormControl,
	Indicator,
	TextField,
} from '@job-ish/ui/components';
import {useModal} from '@job-ish/ui/hooks';
import {postData} from '@job-ish/utilities/data';
import {getEnvironmentBaseUrl} from '@job-ish/utilities/url';
import {
	IconBuildingCommunity,
	IconBuildingFactory2,
	IconClearAll,
	IconFilter,
	IconFlag,
	IconMap2,
	IconSelector,
	IconSparkles,
} from '@tabler/icons-react';
import omit from 'lodash.omit';
import uniqBy from 'lodash.uniqby';
import {useParams, useRouter} from 'next/navigation';
import {twMerge} from 'tailwind-merge';

import {JobBoardFilterSearchField} from './search-field';

const EXPERIENCE_LEVELS = [
	{id: 'EN', name: 'Entry Level'},
	{id: 'MI', name: 'Mid Level'},
	{id: 'SE', name: 'Senior Level'},
	{id: 'EX', name: 'Executive Level'},
];

const DATE_POSTED_OPTIONS = [
	{id: 1, name: 'Last 24 Hours'},
	{id: 7, name: 'Last Week'},
	{id: 14, name: 'Last 14 Days'},
	{id: 30, name: 'Last 30 Days'},
];

export const JobBoardFilter = () => {
	const {filter, updateFilter, clearFilter, shareableSearchParams} = useJobBoardFilter();
	const {loading, setLoading} = useContext(JobBoardFilterContext);
	const [generateKeywordsLoading, setGenerateKeywordsLoading] = useState(false);
	const [generateKeywordsDialogOpen, setGenerateKeywordsDialogOpen] = useState(false);
	const [searchFieldInput, setSearchFieldInput] = useState('');
	const router = useRouter();
	const params = useParams();
	const {user} = useSupabase();
	const {data: subscription} = useSubscription();
	const {filterState, setFilterState} = useFilterState();

	const {show: showDialog} = useModal();

	const [citySearch, setCitySearch] = useState('');
	const [debouncedCitySearch, setDebouncedCitySearch] = useState('');
	useDebounce(
		() => {
			setDebouncedCitySearch(citySearch);
		},
		200,
		[citySearch],
	);

	const {data: jobBoardJobTypes} = useJobBoardJobTypes();
	const {data: jobBoardCountries} = useJobBoardCountries();
	const {data: jobBoardIndustries} = useJobBoardIndustries();
	const {data: jobBoardCities, isFetching: isLoadingCities} = useJobBoardCities(
		debouncedCitySearch,
		filterState.countryCode,
	);
	const {data: jobBoardRegions} = useJobBoardRegions();

	const getFormattedSearchTerms = useCallback(
		(searchFieldInput: string) =>
			searchFieldInput
				.replaceAll('|', ',')
				.replaceAll(' OR ', ',')
				.split(',')
				.filter(v => v.trim() !== '')
				.map(v => (v.startsWith('-') ? `-"${v.slice(1).trim()}"` : `"${v.trim()}"`)),
		[],
	);

	const handleApplySearch = useCallback(() => {
		if (params.id) {
			router.push(PATHS.JobBoard);
		}
		setLoading(true);
		setSearchFieldInput('');
		updateFilter(
			{
				...filterState,
				description: [...new Set([...filterState.description, ...getFormattedSearchTerms(searchFieldInput)])],
				page: 1,
				pageSize: filter.pageSize,
			},
			() => {
				setLoading(false);
			},
		);
	}, [
		params.id,
		setLoading,
		updateFilter,
		filterState,
		getFormattedSearchTerms,
		searchFieldInput,
		filter.pageSize,
		router,
	]);

	const countryDisplayValue = useMemo(
		() => jobBoardCountries?.data.find(country => country.code === filterState.countryCode)?.name,
		[jobBoardCountries, filterState.countryCode],
	);

	const cityDisplayValue = useMemo(
		() =>
			jobBoardCities?.data.results?.find(jobBoardCity => jobBoardCity.name === filterState.cityName)?.name ||
			filterState.cityName,
		[jobBoardCities, filterState.cityName],
	);

	const industryDisplayValue = useMemo(
		() => jobBoardIndustries?.data.find(industry => industry.id === filterState.companyIndustryId)?.name,
		[jobBoardIndustries, filterState.companyIndustryId],
	);

	const regionDisplayValue = useMemo(
		() => jobBoardRegions?.data.find(region => region.id === filterState.regionId)?.name,
		[jobBoardRegions, filterState.regionId],
	);

	const locationSelected = useMemo(
		() => !!filterState.countryCode || !!filterState.cityName,
		[filterState.countryCode, filterState.cityName],
	);

	const regionSelected = useMemo(
		() => filterState.regionId !== undefined && filterState.regionId !== null,
		[filterState.regionId],
	);

	const handleClearSearch = useCallback(() => {
		if (params.id) {
			router.push(PATHS.JobBoard);
		}
		setLoading(true);
		clearFilter(() => {
			setLoading(false);
		});
	}, [clearFilter, params.id, router, setLoading]);

	const filterApplied = useMemo(
		() =>
			Object.entries(omit(filter, ['page', 'pageSize'])).some(([key, value]) =>
				isFilterValueDefined(key, value),
			),
		[filter],
	);

	const showFilterIndicator = useMemo(
		() =>
			Object.entries(
				omit(filter, [
					'page',
					'pageSize',
					'countryCode',
					'cityName',
					'description',
					'companyIndustryId',
					'regionId',
				]),
			).some(([key, value]) => isFilterValueDefined(key, value)),
		[filter],
	);

	const handleGenerateKeywords = useCallback(
		async (resumeId: number) => {
			setGenerateKeywordsLoading(true);
			startTransition(async () => {
				const keywords = await postData<string[]>({
					url: '/api/generate/keywords',
					data: {resume_id: resumeId},
				});

				if (keywords) {
					setFilterState(prev => ({
						...prev,
						description: getFormattedSearchTerms(keywords.join(',')),
					}));
				}

				setGenerateKeywordsLoading(false);
			});
		},
		[getFormattedSearchTerms, setFilterState],
	);

	const handleGenerateKeywordsDialogOpenChange = useCallback(
		(open: boolean) => {
			if (open && (!user || subscription?.status !== 'active')) {
				showDialog<SubscriptionDialogProps>(SubscriptionDialog);
				return;
			}
			setGenerateKeywordsDialogOpen(open);
		},
		[user, subscription?.status, showDialog],
	);

	return (
		<div className="flex flex-col gap-2">
			<form
				className="relative flex flex-col gap-1"
				onSubmit={event => {
					event.preventDefault();
					handleApplySearch();
				}}
			>
				<div className="relative flex grow items-center overflow-hidden">
					<div className="flex w-full items-center gap-2 overflow-x-auto p-1">
						<div className="flex w-full min-w-fit items-center gap-2">
							<DropdownMenu>
								<div className="relative">
									<DropdownMenu.Trigger asChild>
										<Button iconLeft={IconFilter} size="sm">
											Filters
										</Button>
									</DropdownMenu.Trigger>
									{showFilterIndicator && (
										<Indicator className="absolute -right-0.5 -top-0.5" color="danger" size="sm" />
									)}
								</div>
								<DropdownMenu.Content
									align="start"
									className="z-10 max-h-80 w-64 overflow-y-auto"
									hideWhenDetached
								>
									<DropdownMenu.Group>
										<DropdownMenu.Label>Work Arrangement</DropdownMenu.Label>
										<DropdownMenu.Item
											icon={IconClearAll}
											onSelect={event => {
												event.preventDefault();
												setFilterState(prev => ({...prev, hasRemote: undefined}));
											}}
										>
											Clear Selection
										</DropdownMenu.Item>
										<DropdownMenu.RadioGroup
											onValueChange={value =>
												setFilterState(prev => ({
													...prev,
													hasRemote: value === 'true' ? true : value === 'false' ? false : undefined,
												}))
											}
											value={`${filterState.hasRemote}`}
										>
											<DropdownMenu.RadioItem onSelect={event => event.preventDefault()} value="true">
												Remote
											</DropdownMenu.RadioItem>
											<DropdownMenu.RadioItem onSelect={event => event.preventDefault()} value="false">
												On-Site
											</DropdownMenu.RadioItem>
										</DropdownMenu.RadioGroup>
										<DropdownMenu.Separator />
									</DropdownMenu.Group>
									<DropdownMenu.Group>
										<DropdownMenu.Label>Job Type</DropdownMenu.Label>
										<DropdownMenu.Item
											icon={IconClearAll}
											onSelect={event => {
												event.preventDefault();
												setFilterState(prev => ({...prev, typeId: ''}));
											}}
										>
											Clear Selection
										</DropdownMenu.Item>
										<DropdownMenu.RadioGroup
											onValueChange={value => setFilterState(prev => ({...prev, typeId: value}))}
											value={filterState.typeId}
										>
											{jobBoardJobTypes?.data.map(jobType => (
												<DropdownMenu.RadioItem
													key={jobType.id}
													onSelect={event => event.preventDefault()}
													value={jobType.id.toString()}
												>
													{jobType.name}
												</DropdownMenu.RadioItem>
											))}
										</DropdownMenu.RadioGroup>
										<DropdownMenu.Separator />
									</DropdownMenu.Group>
									<DropdownMenu.Group>
										<DropdownMenu.Label>Experience Level</DropdownMenu.Label>
										<DropdownMenu.Item
											icon={IconClearAll}
											onSelect={event => {
												event.preventDefault();
												setFilterState(prev => ({...prev, experienceLevel: ''}));
											}}
										>
											Clear Selection
										</DropdownMenu.Item>
										<DropdownMenu.RadioGroup
											onValueChange={value => setFilterState(prev => ({...prev, experienceLevel: value}))}
											value={filterState.experienceLevel}
										>
											{EXPERIENCE_LEVELS.map(level => (
												<DropdownMenu.RadioItem
													key={level.id}
													onSelect={event => event.preventDefault()}
													value={level.id}
												>
													{level.name}
												</DropdownMenu.RadioItem>
											))}
										</DropdownMenu.RadioGroup>
										<DropdownMenu.Separator />
									</DropdownMenu.Group>
									<DropdownMenu.Group>
										<DropdownMenu.Label>Date Posted</DropdownMenu.Label>
										<DropdownMenu.Item
											icon={IconClearAll}
											onSelect={event => {
												event.preventDefault();
												setFilterState(prev => ({...prev, maxAge: undefined}));
											}}
										>
											Clear Selection
										</DropdownMenu.Item>
										<DropdownMenu.RadioGroup
											onValueChange={value => setFilterState(prev => ({...prev, maxAge: Number(value)}))}
											value={filterState.maxAge?.toString()}
										>
											{DATE_POSTED_OPTIONS.map(option => (
												<DropdownMenu.RadioItem
													key={option.id}
													onSelect={event => event.preventDefault()}
													value={option.id.toString()}
												>
													{option.name}
												</DropdownMenu.RadioItem>
											))}
										</DropdownMenu.RadioGroup>
										<DropdownMenu.Separator />
									</DropdownMenu.Group>
									<DropdownMenu.Group>
										<DropdownMenu.Label>Company Size</DropdownMenu.Label>
										<DropdownMenu.Item
											icon={IconClearAll}
											onSelect={event => {
												event.preventDefault();
												setFilterState(prev => ({...prev, companyMaxSize: undefined}));
											}}
										>
											Clear Selection
										</DropdownMenu.Item>
										<DropdownMenu.RadioGroup
											onValueChange={value =>
												setFilterState(prev => ({...prev, companyMaxSize: Number(value)}))
											}
											value={filterState.companyMaxSize?.toString()}
										>
											<DropdownMenu.RadioItem onSelect={event => event.preventDefault()} value="100">
												Small Business
											</DropdownMenu.RadioItem>
											<DropdownMenu.RadioItem onSelect={event => event.preventDefault()} value="1500">
												Medium Business
											</DropdownMenu.RadioItem>
											<DropdownMenu.RadioItem onSelect={event => event.preventDefault()} value="2000">
												Mid-Market Enterprise
											</DropdownMenu.RadioItem>
											<DropdownMenu.RadioItem onSelect={event => event.preventDefault()} value="1">
												Large Enterprise
											</DropdownMenu.RadioItem>
										</DropdownMenu.RadioGroup>
									</DropdownMenu.Group>
								</DropdownMenu.Content>
							</DropdownMenu>
							<Combobox>
								<Combobox.Trigger
									className="w-0 min-w-44 shrink grow overflow-hidden rounded-md"
									disabled={regionSelected}
								>
									<TextField
										aria-label="Open the country combobox"
										className={twMerge(
											'w-full [&>input]:truncate',
											!regionSelected && 'opacity-100 [&>input]:opacity-100',
										)}
										clearable
										disabled={regionSelected}
										forceShowClearButton
										key={`${!!filterState.countryCode}`}
										onChange={value =>
											setFilterState(prev => ({
												...prev,
												countryCode: value,
												cityName: '',
												regionId: undefined,
											}))
										}
										placeholder="Country"
										prefix={<IconFlag className="h-4 w-4 shrink-0" />}
										readonly
										size="sm"
										suffix={<IconSelector className="h-4 w-4 shrink-0" />}
										value={countryDisplayValue}
									/>
								</Combobox.Trigger>
								<Combobox.Content align="start" className="z-10 w-64" hideWhenDetached side="bottom">
									<Combobox.Input className="rounded-b-none rounded-t-sm" placeholder="Search countries..." />
									<Combobox.Empty>No countries found</Combobox.Empty>
									<Combobox.List className="p-1">
										{jobBoardCountries?.data.map(country => (
											<Combobox.Item
												checked={country.code === filterState.countryCode}
												key={country.code}
												onSelect={() =>
													setFilterState(prev => ({
														...prev,
														countryCode: country.code,
														cityName: '',
														regionId: undefined,
													}))
												}
												value={country.name}
											>
												{country.name}
											</Combobox.Item>
										))}
									</Combobox.List>
								</Combobox.Content>
							</Combobox>
							<Combobox>
								<Combobox.Trigger
									className="w-0 min-w-44 shrink grow overflow-hidden rounded-md"
									disabled={regionSelected}
								>
									<TextField
										aria-label="Open the city combobox"
										className={twMerge(
											'w-full [&>input]:truncate',
											!regionSelected && 'opacity-100 [&>input]:opacity-100',
										)}
										clearable
										disabled={regionSelected}
										forceShowClearButton
										key={`${!!filterState.cityName}`}
										onChange={value =>
											setFilterState(prev => ({...prev, cityName: value, regionId: undefined}))
										}
										placeholder="City Name"
										prefix={<IconBuildingCommunity className="h-4 w-4 shrink-0" />}
										readonly
										size="sm"
										suffix={<IconSelector className="h-4 w-4 shrink-0" />}
										value={cityDisplayValue}
									/>
								</Combobox.Trigger>
								<Combobox.Content
									align="start"
									className="z-10 w-64"
									hideWhenDetached
									shouldFilter={false}
									side="bottom"
								>
									<Combobox.Input
										className="rounded-b-none rounded-t-sm"
										loading={isLoadingCities}
										onValueChange={setCitySearch}
										placeholder="Search cities..."
										value={citySearch}
									/>
									<Combobox.Empty>{isLoadingCities ? 'Loading cities...' : 'No cities found'}</Combobox.Empty>
									<Combobox.List className="relative p-1">
										{uniqBy(jobBoardCities?.data.results, 'name')
											.filter(
												city => !filterState.countryCode || city.country.code === filterState.countryCode,
											)
											.map(jobBoardCity => (
												<Combobox.Item
													checked={jobBoardCity.name === filterState.cityName}
													key={jobBoardCity.geonameid}
													onSelect={() => setFilterState(prev => ({...prev, cityName: jobBoardCity.name}))}
													value={jobBoardCity.name}
												>
													{jobBoardCity.name}
												</Combobox.Item>
											))}
									</Combobox.List>
								</Combobox.Content>
							</Combobox>
							<Combobox>
								<Combobox.Trigger
									className="w-0 min-w-44 shrink grow overflow-hidden rounded-md"
									disabled={locationSelected}
								>
									<TextField
										aria-label="Open the region combobox"
										className={twMerge(
											'w-full [&>input]:truncate',
											!locationSelected && 'opacity-100 [&>input]:opacity-100',
										)}
										clearable
										disabled={locationSelected}
										forceShowClearButton
										key={`${!!filterState.regionId}`}
										onChange={() =>
											setFilterState(prev => ({
												...prev,
												regionId: undefined,
												cityName: '',
												countryCode: '',
											}))
										}
										placeholder="Region"
										prefix={<IconMap2 className="h-4 w-4 shrink-0" />}
										readonly
										size="sm"
										suffix={<IconSelector className="h-4 w-4 shrink-0" />}
										value={regionDisplayValue}
									/>
								</Combobox.Trigger>
								<Combobox.Content align="start" className="z-10 w-64" hideWhenDetached side="bottom">
									<Combobox.Input className="rounded-b-none rounded-t-sm" placeholder="Search regions..." />
									<Combobox.Empty>No regions found</Combobox.Empty>
									<Combobox.List className="p-1">
										{jobBoardRegions?.data.map(region => (
											<Combobox.Item
												checked={region.id === filterState.regionId}
												key={region.id}
												onSelect={() => setFilterState(prev => ({...prev, regionId: region.id}))}
												value={region.id.toString()}
											>
												{region.name}
											</Combobox.Item>
										))}
									</Combobox.List>
								</Combobox.Content>
							</Combobox>
							<Combobox>
								<Combobox.Trigger className="w-0 min-w-44 shrink grow overflow-hidden rounded-md">
									<TextField
										aria-label="Open the industry combobox"
										className="w-full opacity-100 [&>input]:truncate [&>input]:opacity-100"
										clearable
										forceShowClearButton
										key={`${!!filterState.companyIndustryId}`}
										onChange={() => setFilterState(prev => ({...prev, companyIndustryId: undefined}))}
										placeholder="Industry"
										prefix={<IconBuildingFactory2 className="h-4 w-4 shrink-0" />}
										readonly
										size="sm"
										suffix={<IconSelector className="h-4 w-4 shrink-0" />}
										value={industryDisplayValue}
									/>
								</Combobox.Trigger>
								<Combobox.Content align="start" className="z-10 w-72" hideWhenDetached side="bottom">
									<Combobox.Input
										className="rounded-b-none rounded-t-sm"
										placeholder="Search industries..."
									/>
									<Combobox.Empty>No industries found</Combobox.Empty>
									<Combobox.List className="p-1">
										{jobBoardIndustries?.data.map(industry => (
											<Combobox.Item
												checked={industry.id === filterState.companyIndustryId}
												key={industry.id}
												onSelect={() =>
													setFilterState(prev => ({...prev, companyIndustryId: Number(industry.id)}))
												}
												title={industry.name}
												value={industry.name}
											>
												<div className="truncate">{industry.name}</div>
											</Combobox.Item>
										))}
									</Combobox.List>
								</Combobox.Content>
							</Combobox>
						</div>
					</div>
				</div>
				<div className="flex w-full items-start gap-2 p-1">
					<FormControl className="flex-grow">
						<JobBoardFilterSearchField
							emptyFilterFallback={
								<KeywordsDialog
									loading={generateKeywordsLoading}
									onOpenChange={handleGenerateKeywordsDialogOpenChange}
									onSubmit={data => data.resume_id && handleGenerateKeywords(data.resume_id)}
									open={generateKeywordsDialogOpen || generateKeywordsLoading}
									trigger={
										<Badge
											className="m-0.5 h-fit min-h-0"
											color="info"
											interactive
											prefix={<IconSparkles className="h-4 w-4 shrink-0 stroke-yellow9" />}
											size="sm"
										>
											Generate Keywords
										</Badge>
									}
								/>
							}
							input={searchFieldInput}
							loading={loading}
							onChange={value =>
								setFilterState(prev => ({
									...prev,
									description: getFormattedSearchTerms(value.join(',')),
								}))
							}
							onFormSubmit={handleApplySearch}
							onInputChange={setSearchFieldInput}
							value={filterState.description.map(v => v.replaceAll('"', ''))}
						/>
					</FormControl>
				</div>
				{filterApplied && (
					<div className="flex w-full items-center justify-between gap-3">
						<CopyButton
							intent="ghost"
							size="xs"
							text={`${getEnvironmentBaseUrl()}${PATHS.JobBoard.slice(1)}?${shareableSearchParams}`}
						>
							Share Search
						</CopyButton>
						<Button iconRight={IconClearAll} intent="ghost" onPress={handleClearSearch} size="xs">
							Reset Search
						</Button>
					</div>
				)}
			</form>
		</div>
	);
};
