import {forwardRef, useCallback} from 'react';
import {Badge, Button, SearchField, Tooltip} from '@job-ish/ui/components';
import {IconInfoCircle} from '@tabler/icons-react';

import type {KeyboardEvent, ReactNode} from 'react';

type JobBoardFilterSearchField = {
	value?: string[];
	onChange: (value: string[]) => void;
	input: string;
	onInputChange: (searchTerm: string) => void;
	onFormSubmit: () => void;
	loading?: boolean;
	emptyFilterFallback?: ReactNode;
};

export const JobBoardFilterSearchField = forwardRef<HTMLInputElement, JobBoardFilterSearchField>(
	(
		{
			value = [],
			onChange,
			input,
			onInputChange,
			onFormSubmit,
			loading,
			emptyFilterFallback,
		}: JobBoardFilterSearchField,
		forwardedRef,
	) => {
		const handleAddSearchTerm = useCallback(
			(searchTerm: string) => {
				const filteredSearchTerm = searchTerm.replaceAll('"', '').trim();
				if (filteredSearchTerm === '') return;
				if (!value.includes(filteredSearchTerm)) {
					onChange([...value, filteredSearchTerm]);
				}
				onInputChange('');
			},
			[onChange, onInputChange, value],
		);

		const handleDismiss = useCallback(
			(searchTerm: string) => {
				onChange(value.filter(value => value !== searchTerm));
			},
			[onChange, value],
		);

		const handleKeyDown = useCallback(
			(event: KeyboardEvent<HTMLInputElement>) => {
				if (event.key === ',' || event.key === '|') {
					event.preventDefault();
					handleAddSearchTerm(input);
				}

				if (event.key === 'Enter') {
					event.preventDefault();
					onFormSubmit();
				}
			},
			[handleAddSearchTerm, input, onFormSubmit],
		);

		return (
			<div className="flex w-full flex-col gap-1">
				<div className="flex items-center gap-1">
					<SearchField
						className="min-w-0 shrink grow"
						clearable
						onChange={onInputChange}
						onKeyDown={handleKeyDown}
						placeholder="Search a keyword or phrase..."
						ref={forwardedRef}
						size="sm"
						suffix={
							<Tooltip>
								<Tooltip.Trigger asChild className="hidden md:block">
									<IconInfoCircle className="h-4 w-4" />
								</Tooltip.Trigger>
								<Tooltip.Content align="end" className="z-20 flex max-w-72 flex-col gap-1">
									<div>
										Results are based on exact matches with any of the provided values. Separate multiple
										search terms with a comma (,) or a pipe (|).
									</div>
									<div>
										To require multiple search terms, separate them with a space. For example, &quot;React,
										Node.js&quot; will return results that contain either &quot;React&quot; or
										&quot;Node.js&quot;. &quot;React Node.js&quot; will return results that contain both
										&quot;React&quot; and &quot;Node.js&quot;.
									</div>
									<div>To exclude a term, prefix it with a minus (-).</div>
									<div>
										To search for jobs containing specific text in the title, prefix the term with
										&quot;title:&quot;. For example, &quot;title:Software Engineer&quot;.
									</div>
								</Tooltip.Content>
							</Tooltip>
						}
						value={input}
					/>
					<Button intent="outline" loading={loading} size="sm" type="submit">
						Search
					</Button>
				</div>
				<div className="flex h-8 gap-1 overflow-x-auto">
					{value.length > 0
						? value.map((searchTerm, index) => (
								<Badge
									className="h-fit max-w-full"
									color={
										searchTerm.startsWith('-')
											? 'danger'
											: searchTerm.startsWith('title:')
												? 'primary'
												: 'neutral'
									}
									dismissable
									key={`${searchTerm}-${index}`}
									onDismiss={() => handleDismiss(searchTerm)}
									size="sm"
								>
									<span className="truncate">
										{searchTerm.startsWith('-')
											? searchTerm.slice(1)
											: searchTerm.startsWith('title:')
												? searchTerm.slice(6)
												: searchTerm}
									</span>
								</Badge>
							))
						: emptyFilterFallback}
				</div>
			</div>
		);
	},
);

JobBoardFilterSearchField.displayName = 'JobBoardFilterSearchField';
