import {type ReactNode, useCallback} from 'react';
import type {UpsertKeywordsFormProps} from '~/shared/components/forms/keywords';
import {UpsertKeywordsForm} from '~/shared/components/forms/keywords';
import type {UpsertKeywordsFormSchema} from '~/shared/schemas/keywords';
import {Dialog, Overlay} from '@job-ish/ui/components';

import type {SubmitHandler} from 'react-hook-form';

export type KeywordsDialogProps = {
	trigger?: ReactNode;
	open?: boolean;
	onOpenChange?: (open: boolean) => void;
	loading?: boolean;
} & UpsertKeywordsFormProps;

export const KeywordsDialog = ({
	trigger,
	open,
	onOpenChange,
	onSubmit,
	loading,
	...props
}: KeywordsDialogProps) => {
	const handleKeywordsUpsert: SubmitHandler<UpsertKeywordsFormSchema> = useCallback(
		async formData => {
			onSubmit(formData);
			onOpenChange?.(false);
		},
		[onSubmit, onOpenChange],
	);

	return (
		<Dialog onOpenChange={onOpenChange} open={open}>
			<Dialog.Trigger asChild>{trigger}</Dialog.Trigger>
			<Dialog.Content>
				{loading && <Overlay loading />}
				<Dialog.Title>Generate Keywords</Dialog.Title>
				<UpsertKeywordsForm onSubmit={handleKeywordsUpsert} {...props} />
			</Dialog.Content>
		</Dialog>
	);
};
