import {useEffect, useState} from 'react';
import type {JobBoardFilterConfig} from '~/shared/types/job-board-filter';

import type {SetRequired} from 'type-fest';
import {useJobBoardFilter} from './use-job-board-filter';

export const useFilterState = () => {
	const {filter} = useJobBoardFilter();

	const [filterState, setFilterState] = useState<
		SetRequired<
			JobBoardFilterConfig,
			'description' | 'typeId' | 'experienceLevel' | 'countryCode' | 'cityName'
		>
	>({
		description: filter.description || [],
		typeId: filter.typeId || '',
		hasRemote: filter.hasRemote,
		experienceLevel: filter.experienceLevel || '',
		maxAge: filter.maxAge,
		countryCode: filter.countryCode || '',
		cityName: filter.cityName || '',
		companyMaxSize: filter.companyMaxSize,
		companyIndustryId: filter.companyIndustryId,
		regionId: filter.regionId,
	});

	useEffect(() => {
		setFilterState({
			description: filter.description || [],
			typeId: filter.typeId || '',
			hasRemote: filter.hasRemote,
			experienceLevel: filter.experienceLevel || '',
			maxAge: filter.maxAge,
			countryCode: filter.countryCode || '',
			cityName: filter.cityName || '',
			companyMaxSize: filter.companyMaxSize,
			companyIndustryId: filter.companyIndustryId,
			regionId: filter.regionId,
		});
	}, [filter]);

	return {
		filterState,
		setFilterState,
	};
};
