import {type FormEventHandler} from 'react';
import {useForm} from 'react-hook-form';
import {FormInput} from '~/shared/components/inputs/form-input';
import {ResumeSelect} from '~/shared/components/inputs/resume-select';
import type {UpsertKeywordsFormSchema} from '~/shared/schemas/keywords';
import {upsertKeywordsSchema} from '~/shared/schemas/keywords';
import {zodResolver} from '@hookform/resolvers/zod';
import {Button} from '@job-ish/ui/components';
import {IconInfoCircle} from '@tabler/icons-react';

import type {SubmitHandler} from 'react-hook-form';

export type UpsertKeywordsFormProps = {
	onSubmit: SubmitHandler<UpsertKeywordsFormSchema>;
};

export const UpsertKeywordsForm = ({onSubmit}: UpsertKeywordsFormProps) => {
	const {control, handleSubmit, setValue} = useForm<UpsertKeywordsFormSchema>({
		resolver: zodResolver(upsertKeywordsSchema),
		defaultValues: {},
		mode: 'onChange',
		reValidateMode: 'onChange',
	});

	const submitForm: FormEventHandler<HTMLFormElement> = async event => {
		event.preventDefault();
		event.stopPropagation();
		await handleSubmit(onSubmit)();
	};

	return (
		<form className="flex h-full w-full grow flex-col overflow-hidden" onSubmit={submitForm}>
			<div className="flex h-full max-h-full w-full flex-col gap-4 overflow-y-auto p-2">
				<div className="flex h-fit grow flex-col gap-2">
					<FormInput
						control={control}
						label="Resume"
						name="resume_id"
						render={field => (
							<ResumeSelect
								{...field}
								onValueChange={value =>
									setValue('resume_id', value as number, {shouldDirty: true, shouldValidate: true})
								}
								value={field.value || undefined}
							/>
						)}
					/>
					<div className="flex items-start text-pretty text-xs">
						<IconInfoCircle className="mr-1 mt-0.5 h-3 w-3 shrink-0" />
						<span>This feature is powered by AI and may not be perfect.</span>
					</div>
				</div>
			</div>
			<div className="mt-auto flex items-center justify-end gap-3 px-2 pt-4">
				<Button color="primary" type="submit">
					Generate
				</Button>
			</div>
		</form>
	);
};
